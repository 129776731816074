import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

const vueDataEl = document.getElementById('vue-data')
const vueData = vueDataEl ? JSON.parse(vueDataEl.text) : {}
console.log({vueData})

export default new Vuex.Store({
  state: {
    person: vueData.person,
    personalPhoto: vueData.personalPhoto,
    personIsStudent: vueData.personIsStudent,
    personIsEmployee: vueData.personIsEmployee,
    socialLinks: vueData.personalSocialLinks,
    socialNetworks: vueData.socialNetworks,
    menuLinks: vueData.menuLinks || [],
    studentState: null,
    employeeState: null,
    personalBio: null,
    reports: [],
    activities: [],
    activityTypes: [],
    activitySections: [],
    attestationRecords: [],
    scholarships: [],
    interests: [],
    table: [],
    studyTime: [],
  },
  getters: {
    activityType: state => key => state.activityTypes.find(t => t.key === key)
  },
  mutations: {
    setStudentState(state, {studentState}) {
      state.studentState = studentState
    },
    setEmployeeState(state, {employeeState}) {
      state.employeeState = employeeState
    },
    setPersonalPhoto(state, {photo}) {
      state.personalPhoto = photo
    },
    setPersonalBio(state, {bio}) {
      state.personalBio = bio
    },
    setReports(state, {reports}) {
      state.reports = reports
    },
    updateReport(state, {report}) {
      const ix = state.reports.findIndex(r => r.id === report.id)
      if (ix > -1) {
        Vue.set(state.reports, ix, report)
      } else {
        state.reports.unshift(report)
      }
    },
    addReportFiles(state, {id, files}) {
      const ix = state.reports.findIndex(r => r.id === id)
      if (ix > -1) {
        files.forEach(f => state.reports[ix].files.push(f))
      }
    },
    removeReport(state, {id}) {
      state.reports = state.reports.filter(r => r.id !== id)
    },
    setActivityTypes(state, {activityTypes}) {
      state.activityTypes = activityTypes
    },
    setActivitySections(state, {activitySections}) {
      state.activitySections = activitySections
    },
    setActivities(state, {activities}) {
      state.activities = activities
    },
    updateActivities(state, {activity}) {
      const ix = state.activities.findIndex(a => a.id === activity.id)
      if (ix > -1) {
        Vue.set(state.activities, ix, activity)
      } else {
        state.activities.unshift(activity)
      }
    },
    addActivityFiles(state, {id, files}) {
      const ix = state.activities.findIndex(r => r.id === id)
      if (ix > -1) {
        files.forEach(f => state.activities[ix].files.push(f))
      }
    },
    removeActivities(state, {id}) {
      state.activities = state.activities.filter(a => a.id !== id)
    },
    setAttestationRecords(state, {attestationRecords}) {
      state.attestationRecords = attestationRecords
    },
    setScholarships(state, {scholarships}) {
      state.scholarships = scholarships
    },
    setInterests(state, {interests}) {
      state.interests = interests
    },
    setSocialLink(state, {name, link}) {
      Vue.set(state.socialLinks, name, link)
    },
    setTable(state, {table, studyTime}) {
      state.table = table
      state.studyTime = studyTime
    },
  },
  actions: {
    async getStudentState({commit}) {
      const {data: {state: studentState}} = await axios.get('/api/student/state/')
      commit('setStudentState', {studentState})
    },
    async getEmployeeState({commit}) {
      const {data: {state: employeeState}} = await axios.get('/api/employee/state/')
      commit('setEmployeeState', {employeeState})
    },
    async getPersonalBio({commit}) {
      const {data: {bio}} = await axios.get('/api/person/bio/')
      commit('setPersonalBio', {bio})
    },
    async getPersonalPhoto({commit}) {
      const {data: {bio}} = await axios.get('/api/person/photo/')
      commit('setPersonalPhoto', {bio})
    },
    async savePersonalPhoto({commit}, {file}) {
      const formData = new FormData()
      formData.append('photo', file)
      const {data: {photo}} = await axios.post('/api/person/photo/', formData, {
        headers: {'Content-Type': 'multipart/form-data'},
      })
      commit('setPersonalPhoto', {photo})
    },
    async editPersonalBio({commit}, {text}) {
      const {data: {bio}} = await axios.post('/api/person/bio/', {text})
      commit('setPersonalBio', {bio})
    },
    async getReports({commit}) {
      const {data: {reports}} = await axios.get('/api/report/list/')
      commit('setReports', {reports})
    },
    async saveReport({commit}, {id, title, text, newFiles}) {
      const {data: {report}} = await axios.post('/api/report/', {id, title, text})
      await commit('updateReport', {report})
      if (newFiles.length) {
        const formData = new FormData()
        newFiles.forEach(fs => fs.forEach(f => formData.append(f.name, f)))
        const {data: {files}} = await axios.post('/api/report/files/', formData, {
          headers: {'Content-Type': 'multipart/form-data'},
          params: {'id': report.id},
        })
        await commit('addReportFiles', {id: report.id, files})
      }
    },
    async deleteReport({commit}, {id}) {
      await axios.post('/api/report/delete/', {id})
      await commit('removeReport', {id})
    },
    async getActivityTypes({state, commit}) {
      if (state.activityTypes.length) {
        return
      }
      const {data: {types, sections}} = await axios.get('/api/activity/types/')
      commit('setActivityTypes', {activityTypes: types})
      commit('setActivitySections', {activitySections: sections})
    },
    async getActivities({commit}, {section}) {
      const {data: {activities}} = await axios.get('/api/activity/list/?section=' + (section ? section : ''))
      commit('setActivities', {activities})
    },
    async saveActivity({commit}, {id, type, section, date, comment, newFiles}) {
      const {data: {activity}} = await axios.post('/api/activity/', {id, type, section, date, comment})
      await commit('updateActivities', {activity})
      if (newFiles.length) {
        const formData = new FormData()
        newFiles.forEach(fs => fs.forEach(f => formData.append(f.name, f)))
        const {data: {files}} = await axios.post('/api/activity/files/', formData, {
          headers: {'Content-Type': 'multipart/form-data'},
          params: {'id': activity.id},
        })
        await commit('addActivityFiles', {id: activity.id, files})
      }
    },
    async deleteActivity({commit}, {id}) {
      await axios.post('/api/activity/delete/', {id})
      await commit('removeActivities', {id})
    },
    async getAttestationRecords({commit}) {
      const {data: {records}} = await axios.get('/api/attestation/records/')
      commit('setAttestationRecords', {attestationRecords: records})
    },
    async getScholarships({commit}) {
      const {data: {scholarships}} = await axios.get('/api/scholarship/list/')
      commit('setScholarships', {scholarships})
    },
    async getInterests({commit}) {
      const {data: {interests}} = await axios.get('/api/person/interest/list/')
      commit('setInterests', {interests})
    },
    async setInterests({commit}, {interests}) {
      await axios.post('/api/person/interest/set/', {interests})
      commit('setInterests', {interests})
    },
    async saveSocialLink({commit}, {name, link}) {
      await axios.post('/api/person/social_link/save/', {name, link})
      commit('setSocialLink', {name, link})
    },
    async getTable({commit}) {
      const {data: {table, study_time: studyTime}} = await axios.get('/api/student/table/')
      commit('setTable', {table, studyTime})
    },
  },
})
