<template>
  <v-navigation-drawer clipped permanent app>
    <v-list-item
      link href="/profile/"
      :class="getClass('profile')"
    >
      <v-list-item-title>Профиль</v-list-item-title>
    </v-list-item>
    <v-list-item
      v-if="personIsStudent"
      link href="/achievements/"
      :class="getClass('achievements')"
    >
      <v-list-item-title>Портфолио</v-list-item-title>
    </v-list-item>
    <v-list-item
      v-if="personIsStudent"
      link href="/record_book/"
      :class="getClass('record-book')"
    >
      <v-list-item-title>Зачетная книжка</v-list-item-title>
    </v-list-item>
    <v-list-item
      v-if="personIsStudent"
      link href="/table/"
      :class="getClass('table')"
    >
      <v-list-item-title  style="white-space: break-spaces">Расписание</v-list-item-title>
    </v-list-item>
    <v-list-item
      v-if="personIsEmployee"
      link href="/achievements_admin/"
      :class="getClass('achievements_admin')"
    >
      <v-list-item-title>Оценка работ</v-list-item-title>
    </v-list-item>
    <v-divider></v-divider>
    <v-list-item
      v-for="link in menuLinks" :key="link.id"
      dense link :href="link.url" target="_blank"
      style="padding: 0 16px !important;"
    >
      <v-list-item-title style="white-space: break-spaces">{{ link.name }}</v-list-item-title>
    </v-list-item>
  </v-navigation-drawer>
</template>

<script>
  import {mapState} from 'vuex'

  export default {
    name: 'Drawer',
    computed: {
      ...mapState(['personIsStudent', 'personIsEmployee', 'menuLinks']),
    },
    methods: {
      getClass(itemName) {
        return {
          'v-item--active v-list-item--active primary--text': this.$route.name === itemName
        }
      }
    }
  }
</script>

<style scoped>

</style>