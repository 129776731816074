<template>
  <v-app-bar flat dense app clipped-left>
    <v-app-bar-title>Личный кабинет</v-app-bar-title>
    <v-spacer/>
    <v-btn v-if="person" href="/logout/" depressed>Выйти</v-btn>
  </v-app-bar>
</template>

<script>
  import {mapState} from 'vuex'

  export default {
    name: "AppBar",
    computed: {
      ...mapState(['person']),
    }
  }
</script>

<style scoped>

</style>