<template>
  <v-app>
    <nav>
      <app-bar/>
      <drawer v-if="person"/>
    </nav>
    <v-main>
      <v-container>
        <router-view/>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  import AppBar from './components/AppBar'
  import Drawer from './components/Drawer'
  import {mapState} from 'vuex'

  export default {
    name: 'App',
    components: {AppBar, Drawer},
    data: () => ({}),
    computed: {
      ...mapState(['person']),
    },
  };
</script>

<style>
  html {
    overflow-y: auto !important;
  }

  @media (min-width: 1904px) {
    .col-xl-fifth {
      flex: 0 0 20%;
      max-width: 20%;
    }
  }
</style>