import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'lk',
    component: () => import('../components/Lk'),
    children: [{
      path: '/profile/',
      name: 'profile',
      component: () => import('../views/Profile'),
    }, {
      path: '/reports/',
      name: 'reports',
      component: () => import('../views/Reports'),
    }, {
      path: '/achievements/',
      name: 'achievements',
      component: () => import('../views/Achievements'),
    }, {
      path: '/record_book/',
      name: 'record-book',
      component: () => import('../views/RecordBook'),
    }, {
      path: '/table/',
      name: 'table',
      component: () => import('../views/Table'),
    }, {
      path: '/achievements_admin/',
      name: 'achievements_admin',
      component: () => import('../views/AchievementsAdmin'),
    }],
  },
  {
    path: '/login/',
    name: 'login',
    component: () => import('../views/Auth'),
  },
]


const router = new VueRouter({
  mode: 'history',
  routes,
})

export default router
